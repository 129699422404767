import $ from 'jquery';

$('.menu').click(() => {
  $(this).toggleClass('open');
});

// smooth scrolling
$(document).on('click', 'a[href^="#"]', function smoothSroll(event) {
  event.preventDefault();

  const href = $.attr(this, 'href');
  if (href && href !== '#') {
    const $target = $(href);

    if ($target.length) {
      $('html, body').animate({
        scrollTop: $target.offset().top,
      }, 500);
    }
  }
});

// Open FAQ dropdown
$(document).on('click', '.dropdown', function openDropdown() {
  $(this).toggleClass('show');
});
