import $ from 'jquery';

$('[data-modal]').click((event) => {
  event.preventDefault();

  const modalSelector = `#modal-${$(event.target).data('modal')}`;
  $(modalSelector).show();
});

$('.js-close-modal').click((event) => {
  event.preventDefault();

  $('.modal').hide();
});
